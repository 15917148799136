<template>
  <div class="content-wrap">
    <div class="content-select">
      <!-- <div class="select-header">
        <div class="header-right">
          <label>当前组织：</label>
          <organization-select :is-add-all="true" :model="organization"/>
        </div>
      </div> -->
      <div class="select-content">
        <el-tabs v-model="active" @tab-click="handleTabChange">
          <el-tab-pane label="全部" name="all">
            <!-- <query :items="allQueryItems" :value="search" @query="handleQuery" @resetForm="resetForm" /> -->
            <template #label>
              <div style="font-weight: bold;">全部</div>
            </template>
          </el-tab-pane>
          <el-tab-pane label="增值税票" name="vat">
            <!-- <query :items="vatQueryItems" :value="search" @query="handleQuery" @resetForm="resetForm" /> -->
          </el-tab-pane>
          <el-tab-pane label="其他发票" name="other">
            <!-- <query :items="otherQueryItems" :value="search" @query="handleQuery" @resetForm="resetForm" /> -->
          </el-tab-pane>
          <el-tab-pane label="报销发票" name="reimburse" >
            <template #label>
              <div style="font-weight: bold;">报销发票</div>
            </template>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="content-main">
      <all-invoice-table v-if="active == 'all'" :vatTableData="tableData" :status="tableStatus" :search="this.search" @refresh="handleGetIncomeInvoicePoolTable" />
      <vat-invoice-table v-else-if="active == 'vat'" :vatTableData="tableData" :status="tableStatus" :search="this.search" @refresh="handleGetIncomeInvoicePoolTable" />
      <other-invoice-table v-else-if="active == 'other'" :otherTableData="tableData" :status="tableStatus" :search="this.search" @refresh="handleGetIncomeInvoicePoolTable" />
      <reimburse-invoice-table v-else :otherTableData="tableData" :status="tableStatus" :search="this.search" @refresh="handleGetIncomeInvoicePoolTable" />
      <!-- <div class="content-page">
        <el-pagination background :total="total" :page-size="search.size" :page-sizes="[15, 30, 50]" :current-page.sync="search.page" layout="total, sizes, prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { getIncomeInvoicePoolData } from '@/service/invoice-pool';
import Query from '@/components/query';
import AllInvoiceTable from '@/views/invoice-center/component/enterprise-income/AllInvoiceTable';
import VatInvoiceTable from '@/views/invoice-center/component/enterprise-income/VatInvoiceTable';
import OtherInvoiceTable from '@/views/invoice-center/component/enterprise-income/OtherInvoiceTable';
import ReimburseInvoiceTable from '@/views/invoice-center/component/enterprise-income/ReimburseInvoiceTable';
// import OrganizationSelect from '@/components/widgets/OrganizationSelect';
// import { INCOME_INVOICE_POOL_ALL, INCOME_INVOICE_POOL_VAT, INCOME_INVOICE_POOL_OTHER } from '@/content/query-items';
import { pagingMethods } from '@/util/mixins';

export default {
  name: 'EnterpriseIncomeInvoicePool',
  mixins: [pagingMethods],
  components: {
    Query,
    AllInvoiceTable,
    VatInvoiceTable,
    OtherInvoiceTable,
    ReimburseInvoiceTable
  },
  data() {
    return {
      active: 'all',
      // allQueryItems: INCOME_INVOICE_POOL_ALL,
      // vatQueryItems: INCOME_INVOICE_POOL_VAT,
      // otherQueryItems: INCOME_INVOICE_POOL_OTHER,
      organization: {},
      tableData: [],
      tableStatus: {
        tableLoading: false
      },
      total: 0,
      /* 搜索信息 */
      search: {
        size: 15,
        page: 1,
        isIncInvoice: true,
        isVatInvoice: true
      }
    };
  },
  created() {
    const { voucherId = '', type = '' } = this.$route.query;
    if (voucherId && type) {
      this.search.voucherId = voucherId;
      this.search.isVatInvoice = type == 'other' ? false : true;
      this.active = type;
    }
    this.handleGetIncomeInvoicePoolTable();
  },
  methods: {
    refreshList() {
      this.handleGetIncomeInvoicePoolTable();
    },
    resetForm() {
      this.search = {
        size: 15,
        page: 1,
        isIncInvoice: true,
        isVatInvoice: true
      };
    },
    /* 切换tab */
    handleTabChange() {
      if (this.active == 'other') {
        this.search.isVatInvoice = false;
      } else {
        this.search.isVatInvoice = true;
      }
      this.handleGetIncomeInvoicePoolTable();
    },
    handleSizeChange(value) {
      this.search.size = value;
      this.handleGetIncomeInvoicePoolTable();
    },
    handleCurrentChange(value) {
      this.search.page = value;
      this.handleGetIncomeInvoicePoolTable();
    },
    // /* 查询操作 */
    // handleQuery(value) {
    //   // 查询条件
    //   if (typeof value != 'undefined') {
    //     // 开票日期
    //     let invoiceTimeRange = value.invoiceTimeRange;
    //     if (invoiceTimeRange) {
    //       value.invoiceTimeStart = invoiceTimeRange[0];
    //       value.invoiceTimeEnd = invoiceTimeRange[1];
    //       // delete value.invoiceTimeRange
    //     }
    //     // 抵扣日期
    //     let deductionDateRange = value.deductionDateRange;
    //     if (deductionDateRange) {
    //       value.deductionDateStart = deductionDateRange[0];
    //       value.deductionDateEnd = deductionDateRange[1];
    //       // delete value.deductionDateRange;
    //     }
    //     this.search = { ...this.search, ...value };
    //   }
    //   if (Object.keys(value).length === 0) {
    //     this.search = {
    //       total: 0,
    //       size: 15,
    //       current: 1,
    //       incomeSalesType: 'income'
    //     };
    //   }
    //   // 组织信息
    //   let incomeOrgId = this.organization.orgId;
    //   if (typeof incomeOrgId != 'undefined') {
    //     this.search = { ...this.search, incomeOrgId: incomeOrgId };
    //   }
    //   this.handleGetIncomeInvoicePoolTable();
    // },
    // /* 进项发票获取表格数据 */
    handleGetIncomeInvoicePoolTable() {
      this.tableStatus.tableLoading = true;
      // 查询请求
      getIncomeInvoicePoolData(this.search).then((res) => {
        if (res.success) {
          this.tableStatus.tableLoading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.content-select {
  .select-header {
    width: 280px;
    position: relative;
    top: 10px;
    left: calc(100% - 320px);
    z-index: 1;

    .header-right {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;

      ::v-deep .el-input__inner {
        border: none;
        border-radius: 0;
      }
    }

    .header-left span:before {
      content: '|';
      display: inline-block;
      margin-right: 10px;
      background-color: #333;
    }
  }

  .select-content {
    display: inline;
    padding: 0;

    .el-tabs {
      // margin-top: -32px;
    }
  }
}

.content-main {
  min-height: calc(100% - 170px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

::v-deep .el-tabs__item {
  margin-top: 7px;
  color: #333333;
  font-weight: 400;
  font-size: 16px;
}

::v-deep .el-tabs__item.is-active {
  color: #3d94ff;
}
</style>
